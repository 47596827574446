<template>
  <transition name="slide-fade">
    <div class="menu__mobile" :class="{ 'is-opened': isOpenMenu }">
      <div class="container">
        <div class="menu__mobile-content">
          <div ref="headerLogo" class="menu__mobile-top">
            <div class="menu__mobile-logo">
              <button
                class="burger"
                :class="{ 'is-active': isOpenMenu }"
                @click="closeMenu"
              >
                <span class="burger-line burger-top"></span>
                <span class="burger-line burger-middle"></span>
                <span class="burger-line burger-bottom"></span>
              </button>
              <router-link to="" class="menu__mobile-logo--box">
                <img
                  src="@/assets/img/icons/logo-mobile.svg"
                  alt="logo"
                  class="header-menu-logo-desktop"
                />
              </router-link>
            </div>

            <div class="header-top__nav menu__mobile-nav">
              <div class="header-top__settings header-top__nav-item">
                <div v-if="selectedCity" class="header-top__address">
                  <div class="header-top__cities" @click="openSelectCity">
                    <svg
                      width="15"
                      height="19"
                      viewBox="0 0 15 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.49996 19C6.14668 17.863 4.89232 16.6165 3.74998 15.2736C2.03571 13.2569 9.46776e-07 10.2534 9.46776e-07 7.39128C-0.0015185 4.40209 1.82597 1.70659 4.62969 0.562611C7.43342 -0.581368 10.6607 0.0516618 12.8056 2.16631C14.2161 3.54944 15.0063 5.43088 15 7.39128C15 10.2534 12.9642 13.2569 11.2499 15.2736C10.1076 16.6165 8.85324 17.863 7.49996 19ZM7.49996 2.11459C4.54278 2.11808 2.14639 4.47849 2.14285 7.39128C2.14285 8.62181 2.70749 10.7525 5.39462 13.9196C6.0569 14.6985 6.75962 15.4432 7.49996 16.1506C8.24036 15.444 8.94343 14.7004 9.60638 13.9228C12.2924 10.7515 12.8571 8.62075 12.8571 7.39128C12.8535 4.47849 10.4571 2.11808 7.49996 2.11459ZM7.49996 10.5573C5.72477 10.5573 4.28569 9.13982 4.28569 7.39128C4.28569 5.64274 5.72477 4.22527 7.49996 4.22527C9.27516 4.22527 10.7142 5.64274 10.7142 7.39128C10.7142 8.23096 10.3756 9.03625 9.7728 9.62999C9.17 10.2237 8.35244 10.5573 7.49996 10.5573Z"
                        fill="#F50F64"
                      />
                    </svg>
                    {{ selectedCity.attributes.name.substring(0, 3) }}
                  </div>
                  <lang-change />
                </div>
              </div>
            </div>
          </div>
          <ul class="menu__mobile-list">
            <li class="menu__mobile-item">
              <locale-router-link to="/suppliers" class="menu__mobile-link">{{
                $t("suppliersCorp")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link to="/advertisers" class="menu__mobile-link">{{
                $t("advertisers")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link to="/tenants" class="menu__mobile-link">{{
                $t("forTenants")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link to="/landlords" class="menu__mobile-link">{{
                $t("landlordsOwners")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link to="/bidding" class="menu__mobile-link">{{
                $t("tradeMagnum")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link to="/magnum-opt" class="menu__mobile-link">{{
                $t("magnumOpt")
              }}</locale-router-link>
            </li>
            <li class="menu__mobile-item">
              <locale-router-link
                to="/compliance-service"
                class="menu__mobile-link"
                >{{ $t("complianceService") }}</locale-router-link
              >
            </li>
          </ul>
          <div class="menu__mobile-info footer-info footer__info">
            <div class="footer-info__support">{{ $t("support") }}</div>
            <div class="footer-info__number">
              <a href="tel:7766" class="support-number">
                <span>{{ $t("callCenter") }}</span></a
              >
            </div>
            <div class="footer-info__info">{{ $t("website") }}</div>
            <a
              href="https://magnum.kz/ru"
              target="_blank"
              class="footer-info__link"
            >
              <span>Magnum.kz</span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 10.8365H1.33333V1.63527H6V0.320801H1.33333C0.593333 0.320801 0 0.912311 0 1.63527V10.8365C0 11.5595 0.593333 12.151 1.33333 12.151H10.6667C11.4 12.151 12 11.5595 12 10.8365V6.2359H10.6667V10.8365ZM7.33333 0.320801V1.63527H9.72667L3.17333 8.09588L4.11333 9.02258L10.6667 2.56197V4.92144H12V0.320801H7.33333Z"
                  fill="#F50F64"
                />
              </svg>
            </a>
          </div>

          <!-- <div class="header-menu__settings">
            <div
              v-if="selectedCity"
              class="header-menu__address"
              @click="openSelectCity"
            >
              {{ selectedCity.attributes.name.substring(0, 3) }}
            </div>
            <lang-change />
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";
export default {
  name: "AppMenu",
  components: {
    LangChange: () => import("@/components/language/LangChange.vue"),
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  mounted() {
    if (localStorage.getItem("selectedCity")) {
      this.changeSelectedCity(JSON.parse(localStorage.getItem("selectedCity")));
    }
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    }
    this.$router.onReady(() => {
      console.log(
        "On READY",
        this.$route,
        this.$route.params.lang,
        this.$i18n.locale
      );

      if (this.$route.params.lang !== this.$i18n.locale) {
        console.log("SISS", this.$route);
        this.$router.replace({
          query: { city_id: this.selectedCity?.id || 2 },
        });
      }
    });
  },
  watch: {
    "$route.path"() {
      if (this.isOpenMenu) {
        this.closeMenu();
      }
    },
    isOpenMenu: {
      handler() {
        console.log(this.isOpenMenu);
      },
    },
  },
  methods: {
    ...mapActions({
      closeMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
    }),
    ...mapActions("cityModule", {
      changeSelectedCity: actionTypesCity.changeSelectedCity,
    }),
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
